* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

// for scroll bar
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(190, 190, 190, 0.5);
}

/* if buttons disable; they will look like this  */
/* this is a mui global class */
.Mui-disabled {
  background-color: #bebebe !important;
  color: black !important;
}

@media (min-width: 1280px) {
  /* material ui container class */
  .MuiContainer-root {
    /* this measurement has been taken from the figma design */
    max-width: 1416px !important;
  }
}

@media (max-width: 1366px) {
  html {
    font-size: 13.5px;
  }
}

@media (min-width: 600px) {
  // .MuiSnackbar-anchorOriginTopCenter {
  //   top: 48px !important;
  //   left: 63% !important;
  // }
}

/*---------------------------------*/
#mui-alert-snackbar .MuiAlert-icon,
#mui-alert-snackbar .MuiAlert-action {
  display: none !important;
}
// .right-container {
//   width: 72.4137931034%;
//   position: absolute;
//   right: 0;
//   top: 0;
//   z-index: 3;
// }

// .left-container {
//   width: 26.4367816092%;
//   position: absolute;
//   // top: 30px;
//   // left: 70px;
//   z-index: 3;
// }

// .selectNcontineue {
//   /* Rectangle */
// }
.removeLatLan {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 100%;
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  float: right;
  margin-top: -43px;
  margin-right: 5px;
  background: #ade5f7;
  z-index: 999;
  position: relative;
}

// these classes are for animating the modal
.slide-left-animation {
  &-enter {
    opacity: 0;
    transform: translateX(50%);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 350ms, transform 300ms;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transform: translateX(110%);
      transition: opacity 350ms, transform 300ms;
    }
  }
}

.slide-right-animation {
  &-enter {
    opacity: 0;
    transform: translateX(-50%);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 350ms, transform 300ms;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transform: translateX(-110%);
      transition: opacity 350ms, transform 300ms;
    }
  }
}

// for animating components when it unmounts
.item {
  &-enter {
    opacity: 0;
    transform: scale(0.9);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }
  }
}

// .makeStyles-modalsContainer-11{
//   display: none!important;
// }
.navStyle {
  position: "absolute";
  top: 0;
  right: 0;
  padding: "10px";
}

.hideFirst {
  display: block;
  height: 35px;
  width: 30px;

  position: relative;
  text-align: center;
  line-height: 30px;
}
.hideFirst:nth-child(2) {
  display: none !important;
}
.markerNumber {
  position: absolute;
  margin: auto;
  // top: 8px;
  // left: 10px;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 8px;
  font-weight: bolder;
  z-index: 11;
}
.markerNumber.active {
  //color: #000;
}
.history-row.active {
  background-color: #e2f1ff;
}
.markerImage.active {
  filter: invert(1);
}
.markerIdent {
  border: 1px solid #ff0000;
}
.box {
  position: relative;
}
.marker-identifier {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fa7333;
  position: absolute;
  left: -13px;
  top: 10px;
}

.closeImg {
  width: 30px;
  height: 30px;
  float: right;
}

//404
.me404 {
  width: 1000px;
  height: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -400px;
  margin-top: -240px;
}
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #e8ebed;
}
.st1 {
  fill: #ffffff;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #dbdfe1;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #e8ebed;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  stroke: #89949b;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #89949b;
}
.st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #89949b;
}
.st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st12 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st13 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  stroke: #8894a0;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st14 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st15 {
  fill: none;
  stroke: #89949b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#cloud {
  -webkit-animation: cloud 9s infinite ease-in-out;
  -moz-animation: cloud 9s infinite ease-in-out;
  -o-animation: cloud 9s infinite ease-in-out;
  animation: cloud 9s infinite ease-in-out;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes cloud {
  0% {
    opacity: 0.3;
    left: 160px;
    -webkit-transform: skewX(20deg);
  }
  65% {
    opacity: 1;
    left: 114px;
    -webkit-transform: skewX(0deg);
  }
  100% {
    opacity: 0.3;
    left: 160px;
    -webkit-transform: skewX(20deg);
  }
}
@-moz-keyframes cloud {
  0% {
    opacity: 0.3;
    left: 160px;
    -moz-transform: skewX(20deg);
  }
  65% {
    opacity: 1;
    left: 114px;
    -moz-transform: skewX(0deg);
  }
  100% {
    opacity: 0.3;
    left: 160px;
    -moz-transform: skewX(20deg);
  }
}
@-o-keyframes cloud {
  0% {
    opacity: 0.3;
    left: 160px;
    -o-transform: skewX(20deg);
  }
  65% {
    opacity: 1;
    left: 114px;
    -o-transform: skewX(0deg);
  }
  100% {
    opacity: 0.3;
    left: 160px;
    -o-transform: skewX(20deg);
  }
}
@keyframes cloud {
  0% {
    opacity: 0.3;
    left: 160px;
    -webkit-transform: skewX(20deg);
    -moz-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
  }
  65% {
    opacity: 1;
    left: 114px;
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    opacity: 0.3;
    left: 160px;
    -webkit-transform: skewX(20deg);
    -moz-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
  }
}
[not-existing] {
  zoom: 1;
}
#moon {
  transform-origin: 640px 81px;
  -webkit-animation: moon-float 6s infinite ease-in-out;
  -moz-animation: moon-float 6s infinite ease-in-out;
  -o-animation: moon-float 6s infinite ease-in-out;
  animation: moon-float 6s infinite ease-in-out;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes moon-float {
  0% {
    -webkit-transform: translate(0, 20px) scale(1.1);
  }
  65% {
    -webkit-transform: translate(0, 0px) scale(1);
  }
  100% {
    -webkit-transform: translate(0, 20px) scale(1.1);
  }
}
@-moz-keyframes moon-float {
  0% {
    -moz-transform: translate(0, 20px) scale(1.1);
  }
  65% {
    -moz-transform: translate(0, 0px) scale(1);
  }
  100% {
    -moz-transform: translate(0, 20px) scale(1.1);
  }
}
@-o-keyframes moon-float {
  0% {
    -o-transform: translate(0, 20px) scale(1.1);
  }
  65% {
    -o-transform: translate(0, 0px) scale(1);
  }
  100% {
    -o-transform: translate(0, 20px) scale(1.1);
  }
}
@keyframes moon-float {
  0% {
    -webkit-transform: translate(0, 20px) scale(1.1);
    -moz-transform: translate(0, 20px) scale(1.1);
    -ms-transform: translate(0, 20px) scale(1.1);
    transform: translate(0, 20px) scale(1.1);
  }
  65% {
    -webkit-transform: translate(0, 0px) scale(1);
    -moz-transform: translate(0, 0px) scale(1);
    -ms-transform: translate(0, 0px) scale(1);
    transform: translate(0, 0px) scale(1);
  }
  100% {
    -webkit-transform: translate(0, 20px) scale(1.1);
    -moz-transform: translate(0, 20px) scale(1.1);
    -ms-transform: translate(0, 20px) scale(1.1);
    transform: translate(0, 20px) scale(1.1);
  }
}
[not-existing] {
  zoom: 1;
}
#monkey-eye-l {
  transform-origin: 191px 257px;
  -webkit-animation: blink-l 12s infinite ease-in-out;
  -moz-animation: blink-l 12s infinite ease-in-out;
  -o-animation: blink-l 12s infinite ease-in-out;
  animation: blink-l 12s infinite ease-in-out;
}
#monkey-eye-r {
  transform-origin: 205px 256px;
  -webkit-animation: blink-r 12s infinite ease-in-out;
  -moz-animation: blink-r 12s infinite ease-in-out;
  -o-animation: blink-r 12s infinite ease-in-out;
  animation: blink-r 12s infinite ease-in-out;
}
#monkey-arm {
  transform-origin: 155px 298px;
  -webkit-animation: monkey-arm 4s infinite ease-in-out;
  -moz-animation: monkey-arm 4s infinite ease-in-out;
  -o-animation: monkey-arm 4s infinite ease-in-out;
  animation: monkey-arm 4s infinite ease-in-out;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes monkey-arm {
  0% {
    -webkit-transform: rotateZ(-10deg);
  }
  50% {
    -webkit-transform: rotateZ(20deg);
  }
  100% {
    -webkit-transform: rotateZ(-10deg);
  }
}
@-moz-keyframes monkey-arm {
  0% {
    -moz-transform: rotateZ(-10deg);
  }
  50% {
    -moz-transform: rotateZ(20deg);
  }
  100% {
    -moz-transform: rotateZ(-10deg);
  }
}
@-o-keyframes monkey-arm {
  0% {
    -o-transform: rotateZ(-10deg);
  }
  50% {
    -o-transform: rotateZ(20deg);
  }
  100% {
    -o-transform: rotateZ(-10deg);
  }
}
@keyframes monkey-arm {
  0% {
    -webkit-transform: rotateZ(-10deg);
    -moz-transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
  50% {
    -webkit-transform: rotateZ(20deg);
    -moz-transform: rotateZ(20deg);
    -ms-transform: rotateZ(20deg);
    transform: rotateZ(20deg);
  }
  100% {
    -webkit-transform: rotateZ(-10deg);
    -moz-transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes blink-l {
  0% {
    -webkit-transform: rotateX(0deg);
  }
  2% {
    -webkit-transform: rotateX(80deg);
  }
  4%,
  20% {
    -webkit-transform: rotateX(0deg);
  }
  22% {
    -webkit-transform: rotateX(80deg);
  }
  24%,
  30% {
    -webkit-transform: rotateX(0deg);
  }
  32% {
    -webkit-transform: rotateX(80deg);
  }
  34%,
  70% {
    -webkit-transform: rotateX(0deg);
  }
  72% {
    -webkit-transform: rotateX(80deg);
  }
  74%,
  100% {
    -webkit-transform: rotateX(0deg);
  }
}
@-moz-keyframes blink-l {
  0% {
    -moz-transform: rotateX(0deg);
  }
  2% {
    -moz-transform: rotateX(80deg);
  }
  4%,
  20% {
    -moz-transform: rotateX(0deg);
  }
  22% {
    -moz-transform: rotateX(80deg);
  }
  24%,
  30% {
    -moz-transform: rotateX(0deg);
  }
  32% {
    -moz-transform: rotateX(80deg);
  }
  34%,
  70% {
    -moz-transform: rotateX(0deg);
  }
  72% {
    -moz-transform: rotateX(80deg);
  }
  74%,
  100% {
    -moz-transform: rotateX(0deg);
  }
}
@-o-keyframes blink-l {
  0% {
    -o-transform: rotateX(0deg);
  }
  2% {
    -o-transform: rotateX(80deg);
  }
  4%,
  20% {
    -o-transform: rotateX(0deg);
  }
  22% {
    -o-transform: rotateX(80deg);
  }
  24%,
  30% {
    -o-transform: rotateX(0deg);
  }
  32% {
    -o-transform: rotateX(80deg);
  }
  34%,
  70% {
    -o-transform: rotateX(0deg);
  }
  72% {
    -o-transform: rotateX(80deg);
  }
  74%,
  100% {
    -o-transform: rotateX(0deg);
  }
}
@keyframes blink-l {
  0% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  2% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  4%,
  20% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  22% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  24%,
  30% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  32% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  34%,
  70% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  72% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  74%,
  100% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes blink-r {
  0% {
    -webkit-transform: rotateX(0deg);
  }
  2% {
    -webkit-transform: rotateX(80deg);
  }
  4%,
  30% {
    -webkit-transform: rotateX(0deg);
  }
  32% {
    -webkit-transform: rotateX(80deg);
  }
  34%,
  50% {
    -webkit-transform: rotateX(0deg);
  }
  52% {
    -webkit-transform: rotateX(80deg);
  }
  54%,
  100% {
    -webkit-transform: rotateX(0deg);
  }
}
@-moz-keyframes blink-r {
  0% {
    -moz-transform: rotateX(0deg);
  }
  2% {
    -moz-transform: rotateX(80deg);
  }
  4%,
  30% {
    -moz-transform: rotateX(0deg);
  }
  32% {
    -moz-transform: rotateX(80deg);
  }
  34%,
  50% {
    -moz-transform: rotateX(0deg);
  }
  52% {
    -moz-transform: rotateX(80deg);
  }
  54%,
  100% {
    -moz-transform: rotateX(0deg);
  }
}
@-o-keyframes blink-r {
  0% {
    -o-transform: rotateX(0deg);
  }
  2% {
    -o-transform: rotateX(80deg);
  }
  4%,
  30% {
    -o-transform: rotateX(0deg);
  }
  32% {
    -o-transform: rotateX(80deg);
  }
  34%,
  50% {
    -o-transform: rotateX(0deg);
  }
  52% {
    -o-transform: rotateX(80deg);
  }
  54%,
  100% {
    -o-transform: rotateX(0deg);
  }
}
@keyframes blink-r {
  0% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  2% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  4%,
  30% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  32% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  34%,
  50% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  52% {
    -webkit-transform: rotateX(80deg);
    -moz-transform: rotateX(80deg);
    -ms-transform: rotateX(80deg);
    transform: rotateX(80deg);
  }
  54%,
  100% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
[not-existing] {
  zoom: 1;
}
#star1 {
  transform-origin: 643px 338px;
  -webkit-animation: star1 12s infinite linear,
    star1-fade 12s infinite ease-in-out;
  -moz-animation: star1 12s infinite linear, star1-fade 12s infinite ease-in-out;
  -o-animation: star1 12s infinite linear, star1-fade 12s infinite ease-in-out;
  animation: star1 12s infinite linear, star1-fade 12s infinite ease-in-out;
}
#star2 {
  transform-origin: 489px 82px;
  -webkit-animation: star2 12s infinite linear,
    star2-fade 12s infinite ease-in-out;
  -moz-animation: star2 12s infinite linear, star2-fade 12s infinite ease-in-out;
  -o-animation: star2 12s infinite linear, star2-fade 12s infinite ease-in-out;
  animation: star2 12s infinite linear, star2-fade 12s infinite ease-in-out;
}
#star3 {
  transform-origin: 316px 344px;
  -webkit-animation: star3 12s infinite linear,
    star3-fade 12s infinite ease-in-out;
  -moz-animation: star3 12s infinite linear, star3-fade 12s infinite ease-in-out;
  -o-animation: star3 12s infinite linear, star3-fade 12s infinite ease-in-out;
  animation: star3 12s infinite linear, star3-fade 12s infinite ease-in-out;
}
#star4 {
  transform-origin: 126px 182px;
  -webkit-animation: star4 12s infinite linear,
    star4-fade 12s infinite ease-in-out;
  -moz-animation: star4 12s infinite linear, star4-fade 12s infinite ease-in-out;
  -o-animation: star4 12s infinite linear, star4-fade 12s infinite ease-in-out;
  animation: star4 12s infinite linear, star4-fade 12s infinite ease-in-out;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star1 {
  0% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  15% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  30%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-moz-keyframes star1 {
  0% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  15% {
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  30%,
  100% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-o-keyframes star1 {
  0% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  15% {
    -o-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  30%,
  100% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@keyframes star1 {
  0% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  15% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -ms-transform: scale(1) skewX(0deg) rotateZ(360deg);
    transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  30%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star2 {
  0%,
  15% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  30% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  45%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-moz-keyframes star2 {
  0%,
  15% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  30% {
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  45%,
  100% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-o-keyframes star2 {
  0%,
  15% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  30% {
    -o-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  45%,
  100% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@keyframes star2 {
  0%,
  15% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  30% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -ms-transform: scale(1) skewX(0deg) rotateZ(360deg);
    transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  45%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star3 {
  0%,
  30% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  45% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  60%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-moz-keyframes star3 {
  0%,
  30% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  45% {
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  60%,
  100% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-o-keyframes star3 {
  0%,
  30% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  45% {
    -o-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  60%,
  100% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@keyframes star3 {
  0%,
  30% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  45% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -ms-transform: scale(1) skewX(0deg) rotateZ(360deg);
    transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  60%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star4 {
  0%,
  45% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  60% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  75%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-moz-keyframes star4 {
  0%,
  45% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  60% {
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  75%,
  100% {
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@-o-keyframes star4 {
  0%,
  45% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  60% {
    -o-transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  75%,
  100% {
    -o-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
@keyframes star4 {
  0%,
  45% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(0deg);
    transform: scale(0.5) skewX(15deg) rotateZ(0deg);
  }
  60% {
    -webkit-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -moz-transform: scale(1) skewX(0deg) rotateZ(360deg);
    -ms-transform: scale(1) skewX(0deg) rotateZ(360deg);
    transform: scale(1) skewX(0deg) rotateZ(360deg);
  }
  75%,
  100% {
    -webkit-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -moz-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    -ms-transform: scale(0.5) skewX(15deg) rotateZ(720deg);
    transform: scale(0.5) skewX(15deg) rotateZ(720deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star1-fade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  30%,
  100% {
    opacity: 0;
  }
}
@-moz-keyframes star1-fade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  30%,
  100% {
    opacity: 0;
  }
}
@-o-keyframes star1-fade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  30%,
  100% {
    opacity: 0;
  }
}
@keyframes star1-fade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  30%,
  100% {
    opacity: 0;
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star2-fade {
  0%,
  15% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  45%,
  100% {
    opacity: 0;
  }
}
@-moz-keyframes star2-fade {
  0%,
  15% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  45%,
  100% {
    opacity: 0;
  }
}
@-o-keyframes star2-fade {
  0%,
  15% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  45%,
  100% {
    opacity: 0;
  }
}
@keyframes star2-fade {
  0%,
  15% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  45%,
  100% {
    opacity: 0;
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star3-fade {
  0%,
  30% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0;
  }
}
@-moz-keyframes star3-fade {
  0%,
  30% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0;
  }
}
@-o-keyframes star3-fade {
  0%,
  30% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0;
  }
}
@keyframes star3-fade {
  0%,
  30% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0;
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes star4-fade {
  0%,
  45% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  75%,
  100% {
    opacity: 0;
  }
}
@-moz-keyframes star4-fade {
  0%,
  45% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  75%,
  100% {
    opacity: 0;
  }
}
@-o-keyframes star4-fade {
  0%,
  45% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  75%,
  100% {
    opacity: 0;
  }
}
@keyframes star4-fade {
  0%,
  45% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  75%,
  100% {
    opacity: 0;
  }
}
[not-existing] {
  zoom: 1;
}
#sword-path {
  transform-origin: 458px 206px;
  -webkit-animation: tetris-float 3s infinite ease-in-out;
  -moz-animation: tetris-float 3s infinite ease-in-out;
  -o-animation: tetris-float 3s infinite ease-in-out;
  animation: tetris-float 3s infinite ease-in-out;
}
#sword {
  transform-origin: 458px 206px;
  -webkit-animation: sword 4s infinite ease-out;
  -moz-animation: sword 4s infinite ease-out;
  -o-animation: sword 4s infinite ease-out;
  animation: sword 4s infinite ease-out;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes sword {
  0%,
  15% {
    -webkit-transform: rotate(-40deg);
  }
  87% {
    -webkit-transform: rotate(50deg);
  }
  100% {
    -webkit-transform: rotate(-40deg);
  }
}
@-moz-keyframes sword {
  0%,
  15% {
    -moz-transform: rotate(-40deg);
  }
  87% {
    -moz-transform: rotate(50deg);
  }
  100% {
    -moz-transform: rotate(-40deg);
  }
}
@-o-keyframes sword {
  0%,
  15% {
    -o-transform: rotate(-40deg);
  }
  87% {
    -o-transform: rotate(50deg);
  }
  100% {
    -o-transform: rotate(-40deg);
  }
}
@keyframes sword {
  0%,
  15% {
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
  87% {
    -webkit-transform: rotate(50deg);
    -moz-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
  }
  100% {
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
}
[not-existing] {
  zoom: 1;
}
#tetris-path {
  transform-origin: 538px 374px;
  -webkit-animation: tetris-float 5s infinite ease-in-out;
  -moz-animation: tetris-float 5s infinite ease-in-out;
  -o-animation: tetris-float 5s infinite ease-in-out;
  animation: tetris-float 5s infinite ease-in-out;
}
#tetris {
  transform-origin: 538px 374px;
  -webkit-animation: rotate 10s steps(1) infinite alternate;
  -moz-animation: rotate 10s steps(1) infinite alternate;
  -o-animation: rotate 10s steps(1) infinite alternate;
  animation: rotate 10s steps(1) infinite alternate;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  25% {
    -webkit-transform: rotateZ(90deg);
  }
  50% {
    -webkit-transform: rotateZ(180deg);
  }
  75% {
    -webkit-transform: rotateZ(270deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: rotateZ(0deg);
  }
  25% {
    -moz-transform: rotateZ(90deg);
  }
  50% {
    -moz-transform: rotateZ(180deg);
  }
  75% {
    -moz-transform: rotateZ(270deg);
  }
  100% {
    -moz-transform: rotateZ(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform: rotateZ(0deg);
  }
  25% {
    -o-transform: rotateZ(90deg);
  }
  50% {
    -o-transform: rotateZ(180deg);
  }
  75% {
    -o-transform: rotateZ(270deg);
  }
  100% {
    -o-transform: rotateZ(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  25% {
    -webkit-transform: rotateZ(90deg);
    -moz-transform: rotateZ(90deg);
    -ms-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }
  50% {
    -webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  75% {
    -webkit-transform: rotateZ(270deg);
    -moz-transform: rotateZ(270deg);
    -ms-transform: rotateZ(270deg);
    transform: rotateZ(270deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes tetris-float {
  0% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -webkit-transform: translate(0px, 30px) scale(1);
  }
  100% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
  }
}
@-moz-keyframes tetris-float {
  0% {
    -moz-transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -moz-transform: translate(0px, 30px) scale(1);
  }
  100% {
    -moz-transform: translate(0px, 0px) scale(0.9);
  }
}
@-o-keyframes tetris-float {
  0% {
    -o-transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -o-transform: translate(0px, 30px) scale(1);
  }
  100% {
    -o-transform: translate(0px, 0px) scale(0.9);
  }
}
@keyframes tetris-float {
  0% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
    -moz-transform: translate(0px, 0px) scale(0.9);
    -ms-transform: translate(0px, 0px) scale(0.9);
    transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -webkit-transform: translate(0px, 30px) scale(1);
    -moz-transform: translate(0px, 30px) scale(1);
    -ms-transform: translate(0px, 30px) scale(1);
    transform: translate(0px, 30px) scale(1);
  }
  100% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
    -moz-transform: translate(0px, 0px) scale(0.9);
    -ms-transform: translate(0px, 0px) scale(0.9);
    transform: translate(0px, 0px) scale(0.9);
  }
}
[not-existing] {
  zoom: 1;
}
#triforce {
  transform-origin: 175px 91px;
  -webkit-animation: triforce-float 3s infinite ease-in-out;
  -moz-animation: triforce-float 3s infinite ease-in-out;
  -o-animation: triforce-float 3s infinite ease-in-out;
  animation: triforce-float 3s infinite ease-in-out;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes triforce-float {
  0% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -webkit-transform: translate(0px, 30px) scale(1);
  }
  100% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
  }
}
@-moz-keyframes triforce-float {
  0% {
    -moz-transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -moz-transform: translate(0px, 30px) scale(1);
  }
  100% {
    -moz-transform: translate(0px, 0px) scale(0.9);
  }
}
@-o-keyframes triforce-float {
  0% {
    -o-transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -o-transform: translate(0px, 30px) scale(1);
  }
  100% {
    -o-transform: translate(0px, 0px) scale(0.9);
  }
}
@keyframes triforce-float {
  0% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
    -moz-transform: translate(0px, 0px) scale(0.9);
    -ms-transform: translate(0px, 0px) scale(0.9);
    transform: translate(0px, 0px) scale(0.9);
  }
  65% {
    -webkit-transform: translate(0px, 30px) scale(1);
    -moz-transform: translate(0px, 30px) scale(1);
    -ms-transform: translate(0px, 30px) scale(1);
    transform: translate(0px, 30px) scale(1);
  }
  100% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
    -moz-transform: translate(0px, 0px) scale(0.9);
    -ms-transform: translate(0px, 0px) scale(0.9);
    transform: translate(0px, 0px) scale(0.9);
  }
}
[not-existing] {
  zoom: 1;
}
.not-found-page {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.errContainer {
  width: 600px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  /* background: red; */
  left: 0;
  right: 0;
  font-weight: bold;
  color: #333;
}
.errBlock {
  font-size: 21px;
  width: 300px;
  min-height: 80px;
  align-items: center;
  margin: 20px;
  text-align: center;
}
.errBlock a {
  text-decoration: none;
  color: #1e75c3;
}

// for masonry layout
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  // margin-bottom: 30px;
}

a {
  color: rgb(28, 121, 180);
  text-decoration: none;
  margin: 0 3px;
}
